@import "../global.scss";

.e_input {
    &:focus {
        outline: none !important;
    }

    font-family: 'Noto Sans';
}

input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

// OTP Input
.e_otp_wrapper {
    input::selection {
        color: transparent;
        background: transparent;
    }

    input {
        cursor: default;
        outline: none;
        border: none;
        color: transparent;
        position: absolute;
        height: 3.5rem;
        background: transparent;
    }

    label {
        div {
            border-radius: 0.75rem;
            background: transparent;
        }
    }
}

.e_input {
    &::placeholder {
        font-family: "Noto Sans";
    }
}

.e_password_input {
    &:focus {
        outline: none !important;
    }

    letter-spacing: 0.2rem;

    &::placeholder {
        font-family: "Noto Sans";
        font-size: 0.625rem;
        letter-spacing: 0.2rem;
    }
}

.e_textarea_wrap {
    textarea {
        outline: none;
        font-family: "Noto Sans";
        font-size: 1rem;
        font-weight: 400 !important;
        color: $raisin_black;

        &::placeholder {
            font-family: "Noto Sans";
            font-size: 0.875rem;
            font-weight: 400 !important;
        }

        &:focus {
            border: 0.063rem solid $indigo ;
        }

        &::-webkit-scrollbar-thumb {
            background: $indigo !important;
            border-radius: 5rem !important;
        }

        &::-webkit-scrollbar {
            width: 0.20rem !important;
            display: block !important;
        }
    }
}



.e_resource {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .e_slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0.125rem;
        bottom: 0;
        background: $platinum;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 1.875rem;

        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 2px;
            top: 2px;
            background: $white;

            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }
    }

    input:checked+.e_slider {
        background-color: #E02126;
    }

    input:focus+.e_slider {
        box-shadow: 0 0 0.063rem transparent;
    }

    input:checked+.e_slider:before {
        -webkit-transform: translateX(1.375rem);
        -ms-transform: translateX(1.375rem);
        transform: translateX(1.375rem);
    }

}