@import "../global.scss";

.e_img_wrapper {
    height: 159px;
    width: 100%;
    border: 1px solid $platinum_light;
    border-radius: 16px 16px 0 0;
    position: relative;
    overflow: hidden;
}
