@charset "UTF-8";
/*
 *   File : global.scss
 *   Author : https://evoqins.com
 *   Description : SCSS file
 *   Integrations : NA
 *   Version : 1.0.0
*/

// @import "../Style/style.scss";
@import "../Style/font.scss";
@import "../Style/responsive.scss";
@import "../Style/color.scss";

.e-body::-webkit-scrollbar {
    display: none !important;
}

/*===============================
        Font family
================================= */

.e-primary-font-playfair {
    font-family: "Playfair Display";
}

.e-alt-font-noto-sans {
    font-family: "Noto Sans";
}

/*===============================
        Font color
================================= */

.e-text-white {
    color: $white;
}

.e-text-black {
    color: $black;
}

.e-text-red {
    color: $red;
}

.e-text-rich-black {
    color: $rich_black;
}

.e-text-raisin-black {
    color: $raisin_black;
}

.e-text-dark-silver {
    color: $dark_silver;
}

.e-text-sonic-silver {
    color: $sonic_silver;
}

.e-text-lust {
    color: $lust;
}

.e-text-davys-grey {
    color: $davys_grey;
}

.e-text-indigo {
    color: $indigo;
}

.e-text-deep-violet {
    color: $deep_violet;
}

.e-text-charleston-green {
    color: $charleston_green;
}

.e-text-spanish-gray {
    color: $spanish_gray;
}

.e-text-rich-black-light {
    color: $rich_black_light;
}

.e-text-outer-space {
    color: $outer_space;
}

.e-text-dark-jungle-green {
    color: $dark_jungle_green;
}

.e-text-black-olive {
    color: $black_olive;
}

.e-text-lust {
    color: $lust;
}

.e-text-black-chocolate {
    color: $black_chocolate;
}

.e-text-dark-black {
    color: $dark_black;
}

.e-text-color-vivid-malachite {
    color: $vivid_malachite;
}

/*===============================
        caret-color
================================= */
.e-caret-color-transparent {
    caret-color: transparent;
}

/*===============================
        Background color 
================================= */
.e-bg-transparent {
    background: transparent !important;
}

.e-bg-black {
    background: $black;
}

.e-bg-white {
    background: $white !important;
}

.e-bg-indigo {
    background: $indigo;
}

.e-bg-bright-gray {
    background: $bright_gray;
}

.e-bg-ghost-white {
    background: $ghost_white;
}

.e-bg-azureish-white {
    background: $azureish_white;
}

.e-bg-fire-engine-red {
    background: $fire_engine_red;
}

.e-bg-cultured {
    background: $cultured;
}

.e-bg-lavender {
    background: $lavender;
}

.e-bg-anti-flash-white {
    background: $anti_flash_white;
}

.e-bg-platinum {
    background: $platinum;
}

.e-bg-lust {
    background: $lust;
}

.e-bg-magnolia {
    background: $magnolia;
}

.e-bg-philippine-green {
    background: $philippine_green;
}

.e-bg-lotion {
    background: $lotion;
}

.e-bg-harlequin-green {
    background: $harlequin_green;
}

.e-bg-candy-apple-red {
    background: $candy_apple_red;
}

.e-bg-light-red {
    background: $light_red;
}

.e-bg-tea-green {
    background: $tea_green;
}

.e-bg-alice-blue {
    background: $alice_blue;
}

.e-bg-azureish-white-light {
    background: $azureish_white_light;
}

.e-bg-lavender-blush {
    background: $lavender_blush;
}

.e-bg-cosmic-latte {
    background: $cosmic_latte;
}

.e-bg-alice-blue-light {
    background: $alice_blue_light;
}

.e-bg-lavender-blush-light {
    background: $lavender_blush_light;
}

.e-bg-anti-flash-white-dark {
    background: $anti_flash_white_dark;
}

.e-bg-spanish-yellow {
    background: $spanish_yellow;
}

.e-bg-peach-yellow {
    background: $peach_yellow;
}

.e-bg-periwinkle {
    background: $periwinkle;
}

.e-bg-lavender-blue {
    background: $lavender_blue;
}

.e-bg-linen {
    background: $linen;
}

.e-bg-overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.e-bg-columbia-blue {
    background: $columbia_blue;
}

.transform-180deg {
    transform: rotate(180deg);
}

/*===============================
        Background gradient 
================================= */

.e-video-player-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
}

/*===============================
        Ellipse  
================================= */
.e-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.e-ellipse-3-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.e-ellipse-4-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.e-ellipse-single {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

// Word breal
.break-word {
    word-wrap: break-word;
}

.modal::-webkit-scrollbar {
    display: none;
}

.offcanvas-backdrop {
    height: 100%;
    width: 100%;
}