@import "../global.scss";


.css-13cymwt-control {
    border: 1px solid #213366 !important;
    border-radius: 12px !important;
}

// tag selector
.e-tag-select {

    .select__input-container {
        cursor: pointer;
    }

    svg {
        color: $raisin_black !important;
        width: 1rem;
        height: 1rem;
    }

    .select__control {
        border: 0.063rem solid $platinum_light !important;
        border-radius: 0.5rem !important;
    }

    input {
        padding: 0.125rem 0 !important;
    }

    .select__placeholder {
        font-family: "Noto Sans";
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1rem;
        color: $davys_grey;
    }

    .select__option {
        font-family: 'Noto Sans' !important;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;
        color: $raisin_black !important;
    }

    .select__option--is-focused {
        background: $indigo !important;
        color: $white !important;
        cursor: pointer !important;
    }

    .select__multi-value {
        background: $periwinkle !important;
        border-radius: 0.75rem !important;
        padding: 0 0.375rem !important;


        .select__multi-value__label {
            font-family: 'Noto Sans' !important;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.375rem;
            color: $raisin_black !important;
            padding: 0 0.25rem !important;
        }
    }

    .select__multi-value__remove {
        background: transparent !important;
        color: $raisin_black !important;

        svg {
            border: 0.063rem solid $raisin_black;
            border-radius: 50%;
        }
    }

    .select__control--menu-is-open {
        .select__dropdown-indicator {
            transform: rotate(180deg);
            transition: all 0.3s ease-in-out;
        }
    }

    .select__indicator {
        svg {
            color: $raisin_black !important;
        }
    }


}

.e-tag-select-input {
    .select__input-container {
        cursor: text !important;
    }

    .select__value-container {
        height: 2.5rem;
        overflow: auto;

        &::-webkit-scrollbar-thumb {
            background: $indigo !important;
            border-radius: 3rem !important;
        }

        &::-webkit-scrollbar {
            width: 0.125rem !important;
            display: block !important;
        }
    }
}

.e-modal-select {
    svg {
        color: $raisin_black !important;
    }

    input {
        font-family: 'Noto Sans' !important;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $raisin_black !important;
    }

    .css-qbdosj-Input,
    .css-166bipr-Input {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        font-family: 'Noto Sans' !important;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $raisin_black !important;
    }
}