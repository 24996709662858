/*
 *   File : font.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index page for font
 *   Integrations :
 *   Version : v1.0
 *   Created : 
 */
/* Import Fonts */

/* Playfair Display font family */
@font-face {
    font-family: 'Playfair Display';
    src: url('../Assets/Font/Playfair-display/PlayfairDisplay-Black.ttf');
    font-weight: 900;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../Assets/Font/Playfair-display/PlayfairDisplay-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../Assets/Font/Playfair-display/PlayfairDisplay-Regular.ttf');
    font-weight: 400;
}


//font Noto sans
@font-face {
    font-family: 'Noto Sans';
    src: url('../Assets/Font/Noto-sans/static/NotoSans-Black.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../Assets/Font/Noto-sans/static/NotoSans-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('../Assets/Font/Noto-sans/static/NotoSans-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('../Assets/Font/Noto-sans/static/NotoSans-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('../Assets/Font/Noto-sans/static/NotoSans-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('../Assets/Font/Noto-sans/static/NotoSans-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('../Assets/Font/Noto-sans/static/NotoSans-Thin.ttf');
    font-weight: 200;
}

