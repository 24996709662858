@import "../global.scss";

.e_modal_body_wrapper {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        width: 0.25rem !important;
        display: block !important;
    }
}