@import "../global.scss";

.e_statistics_card_wrapper {
    filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.12));

    &:hover {
        // box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
}

.e_quiz_card_wrapper{
    background: url("../../Assets/Image/Card/quiz-card-bg.png") no-repeat;
    background-position: center;
    background-size: cover;
}