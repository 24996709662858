@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@import "../Style/font.scss";
@import "../Style/color.scss";

@media only screen and (max-width: 1400px) {
   .res-gap-0-16px {
      gap: 0 1rem !important;
   }
}

@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 974px) {
   .e-res-justify-content-start {
      justify-content: start !important;
   }

   .e-res-h-262px {
      height: 180px !important;
   }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 574px) {
   .margin-res-auto {
      margin: auto !important;
   }
}

@media only screen and (max-width: 440px) {}


@media only screen and (max-width: 380px) {}

/* ==============================
   Small Phone
   ==============================*/
@media only screen and (max-width: 319px) {}