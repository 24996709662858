@import "../global.scss";

//table style
.e-leader-board-table {
    .rdt_TableHead {
        font-family: 'Noto Sans' !important;
        font-weight: 600 !important;
        font-size: 0.875rem !important;
        line-height: 1.125rem;
        color: $raisin_black;
        border-bottom-color: transparent !important;
        padding-left: 1.5rem;
    }

    .rdt_TableRow {
        font-family: 'Noto Sans';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $rich_black;
        border-bottom-color: transparent !important;
        padding-left: 1.5rem;
    }

    .rdt_TableRow:nth-child(odd) {
        background-color: $lotion;
        cursor: pointer;

        &:hover {
            background: $azureish_white;
        }
    }

    .rdt_TableRow:nth-child(even) {
        &:hover {
            background: $azureish_white;
        }

        cursor: pointer;

    }

    .rdt_TableHeadRow {
        border-bottom-color: transparent !important;

    }

    .rdt_TableCell,
    .rdt_TableCol {
        padding-left: 0;
    }

}

// country admin  
.e-country-admin-table {
    .rdt_TableHead {
        font-family: 'Noto Sans' !important;
        font-weight: 600 !important;
        font-size: 0.875rem !important;
        line-height: 1.125rem;
        color: $raisin_black;
        border-bottom-color: transparent !important;
        padding-left: 1.5rem;
    }

    .rdt_TableRow {
        font-family: 'Noto Sans';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $rich_black;
        border-bottom-color: transparent !important;
        padding-left: 1.5rem;
        cursor: pointer;
    }

    .rdt_TableRow:nth-child(odd) {
        background-color: $lotion;

        &:hover {
            background: $azureish_white;
        }
    }

    .rdt_TableRow:nth-child(even) {

        &:hover {
            background: $azureish_white;
        }
    }

    .rdt_TableHeadRow {
        border-bottom-color: transparent !important;
    }

    .rdt_TableCell,
    .rdt_TableCol {
        padding-left: 0;
    }

    .rdt_Pagination {
        border-top-color: transparent !important;
    }

}

.e-table {
    .rdt_TableHead {
        font-family: 'Noto Sans' !important;
        font-weight: 600 !important;
        font-size: 0.875rem !important;
        line-height: 1.125rem;
        color: $raisin_black;
        border-bottom-color: transparent !important;
        padding-left: 1.5rem;
    }

    .rdt_TableRow {
        font-family: 'Noto Sans';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $rich_black;
        border-bottom-color: transparent !important;
        padding-left: 1.5rem;
    }

    .rdt_TableRow:nth-child(odd) {
        background-color: $lotion;

        &:hover {
            background: $azureish_white;
        }
    }

    .rdt_TableRow:nth-child(even) {

        &:hover {
            background: $azureish_white;
        }
    }


    .rdt_TableHeadRow {
        border-bottom-color: transparent !important;
    }

    .rdt_TableCell,
    .rdt_TableCol {
        padding-left: 0;
    }

    .rdt_Pagination {
        border-top-color: transparent !important;
    }

    .fpBGKh {
        &::-webkit-scrollbar-thumb {
            background: $indigo !important;
            border-radius: 3rem !important;
        }

        &::-webkit-scrollbar {
            height: 0.25rem !important;
            display: block !important;
        }
    }


}

.sc-gjLLEI {
    background: $anti_flash_white !important;
    border-radius: 0.5rem !important;
    margin-left: 0.75rem;
}

.rdt_Pagination {
    padding-right: 1.5rem !important;
    padding-top: 1.5rem;
    font-family: 'Noto Sans';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $davys_grey;
}

.sc-dmXWDj {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        height: 0.25rem !important;
        display: block !important;
    }
}

//Buttons

.e-accept {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    border-radius: 0.25rem !important;
    background: $philippine_green !important;
}

.e-reject {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    border-radius: 0.25rem !important;
    background: $boston_university_red !important;
}


.e-members-table,
.e-store-table,
.e-members-detail-table {
    .rdt_TableHead {
        font-family: 'Noto Sans' !important;
        font-weight: 600 !important;
        font-size: 0.875rem !important;
        line-height: 1.125rem;
        color: $raisin_black;
        border-bottom-color: transparent !important;
        padding-left: 1.5rem;
    }

    .rdt_TableRow {
        font-family: 'Noto Sans';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $rich_black;
        border-bottom-color: transparent !important;
        padding-left: 1.5rem;
        cursor: pointer;
    }

    .rdt_TableRow:nth-child(odd) {
        background-color: $lotion;

        &:hover {
            background: $azureish_white;
        }
    }

    .rdt_TableRow:nth-child(even) {

        &:hover {
            background: $azureish_white;
        }
    }


    .rdt_TableHeadRow {
        border-bottom-color: transparent !important;
    }

    .rdt_TableCell,
    .rdt_TableCol {
        padding-left: 0;
    }

    .rdt_Pagination {
        border-top-color: transparent !important;
    }

    .sc-dmXWDj {
        &::-webkit-scrollbar-thumb {
            background: $indigo !important;
            border-radius: 3rem !important;
        }

        &::-webkit-scrollbar {
            height: 0.25rem !important;
            display: block !important;
        }
    }

}

.sc-lnsjTu {
    select {
        outline: 0.063rem solid $platinum_light !important;
        border-radius: 0.25rem;
        &:focus {
            outline: 0.063rem solid $indigo !important;
        }
    }
}