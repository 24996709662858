@import "../global.scss";

.e_interior_paint {

    .first::marker {
        color: $spanish_yellow;
    }

    .second::marker {
        color: $lust;
    }

    .third::marker {
        color: $indigo;
    }
}

.e_business_stats {
    .first::marker {
        color: $interdimensional_blue;
    }

    .second::marker {
        color: $vivid_malachite;
    }

    .third::marker {
        color: $lust;
    }
}


td,
th {
    // border: 1px solid #dddddd;
    text-align: left;
    padding: 1rem 1.5rem;
}

th {
    color: $davys_grey;
}

tr:nth-child(even) {
    background-color: rgba(13, 13, 13, 0.02);
}


.e_top_selling_scroll {
    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        width: 0.125rem !important;
        height: 0.125rem !important;
        display: block !important;
    }
}