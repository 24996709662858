@import "../global.scss";

.e_repeatnotification {
    position: relative;
    display: inline-block;
    width: 3.375rem;
    height: 1.75rem;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .e_slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0.125rem;
        bottom: 0;
        background: $platinum;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 1.875rem;

        &:before {
            position: absolute;
            content: "";
            height: 1.375rem;
            width: 1.375rem;
            left: 0.188rem;
            top: 0.188rem;
            background: $indigo;

            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }
    }

    input:checked+.e_slider {
        background-color: $azureish_white_light;
    }

    input:focus+.e_slider {
        box-shadow: 0 0 0.063rem transparent;
    }

    input:checked+.e_slider:before {
        -webkit-transform: translateX(1.375rem);
        -ms-transform: translateX(1.375rem);
        transform: translateX(1.375rem);
    }

}

.e_schedule {
    &::-webkit-scrollbar-thumb {
        background: $platinum_light !important;
        border-radius: 1.5rem !important;
    }

    &::-webkit-scrollbar {
        width: 0.188rem;
        display: block !important;
    }
}