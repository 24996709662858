@import "../global.scss";


.e_tab_active {
    background: $indigo;

    p {
        color: $white;
    }
}

.e_tab_active_toggle {
    background: $indigo;
}

.e_tab_toggle {
    &:hover {
        background: $indigo;
    }
}

.e_tab {
    p {
        color: $rich_black;
    }

    &:hover {
        background: $indigo;

        p {
            color: $white;
        }
    }
}

.e_left_nav_scroll {

    height: calc(100vh - 130px);

    &::-webkit-scrollbar-thumb {
        background: $indigo !important;
        border-radius: 3rem !important;
    }

    &::-webkit-scrollbar {
        width: 0.25rem !important;
        display: block !important;
    }
}