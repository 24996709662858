/*File : color.scss
*   Author : https://evoqins.com
*   Description : SCSS file for define and export colors
*   Integrations : NA
*   Version : 1.0.0
*/

// Color declaration
$white: #ffffff;
$black: #000000;
$red: #f6172d;
$indigo: #213366;
$rich_black: #090914;
$columbia_blue: #cbd5e1;
$raisin_black: #212121;
$bright_gray: #e8eaf4;
$dark_silver: #6d6d6d;
$sonic_silver: #7a7a7a;
$lust: #e02126;
$davys_grey: #555555;
$platinum_light: #e3e5e5;
$ghost_white: #f5f6ff;
$azureish_white: #d7e1ff;
$fire_engine_red: #c91d30;
$deep_violet: #23005b;
$spanish_yellow: #f5b01a;
$cultured: #f7f8f9;
$interdimensional_blue: #3e00c2;
$vivid_malachite: #08d110;
$lavender: #e4ebff;
$anti_flash_white: #f3f3f3;
$platinum: #e5e5e5;
$cool_grey: #8696bb;
$magnolia: #f9f5ff;
$charleston_green: #2d2d2d;
$lotion: #fafafa;
$spanish_gray: #9d9d9d;
$harlequin_green: #40d51a;
$candy_apple_red: #fe0707;
$light_red: #ffcdcd;
$tea_green: #d9f7d1;
$rich_black_light: #030723;
$philippine_green: #027a48;
$boston_university_red: #cf0000;
$alice_blue: #edf3ff;
$azureish_white_light: #ddebfc;
$lavender_blush: #fef2f2;
$cosmic_latte: #fef9e8;
$alice_blue_light: #e8f2ff;
$lavender_blush_light: #fff5f5;
$bright_gray_light: #ecedef;
$anti_flash_white_dark: #f0f0f0;
$outer_space: #44475b;
$dark_jungle_green: #101828;
$peach_yellow: #fbe3ad;
$periwinkle: #c2ccf0;
$black_olive: #3b3b3b;
$royal_blue: #306be0;
$lavender_blue: #c3dcfa;
$black_chocolate: #132a00;
$linen: #ffeaeb;
$arsenic: #43433c;
$gray_light: #808080;
$dark_black: #010101;
$light_steel_blue: #d0d5dd;
$platinum_dark: #E3E5E5;

:export {
    white: $white;
    black: $black;
    red: $red;
    indigo: $indigo;
    rich_black: $rich_black;
    columbia_blue: $columbia_blue;
    raisin_black: $raisin_black;
    bright_gray: $bright_gray;
    dark_silver: $dark_silver;
    sonic_silver: $sonic_silver;
    lust: $lust;
    davys_grey: $davys_grey;
    ghost_white: $ghost_white;
    azureish_white: $azureish_white;
    fire_engine_red: $fire_engine_red;
    deep_violet: $deep_violet;
    spanish_yellow: $spanish_yellow;
    cultured: $cultured;
    interdimensional_blue: $interdimensional_blue;
    vivid_malachite: $vivid_malachite;
    lavender: $lavender;
    anti_flash_white: $anti_flash_white;
    platinum: $platinum;
    cool_grey: $cool_grey;
    magnolia: $magnolia;
    charleston_green: $charleston_green;
    lotion: $lotion;
    spanish_gray: $spanish_gray;
    harlequin_green: $harlequin_green;
    candy_apple_red: $candy_apple_red;
    light_red: $light_red;
    tea_green: $tea_green;
    rich_black_light: $rich_black_light;
    philippine_green: $philippine_green;
    boston_university_red: $boston_university_red;
    alice_blue: $alice_blue;
    azureish_white_light: $azureish_white_light;
    lavender_blush: $lavender_blush;
    cosmic_latte: $cosmic_latte;
    alice_blue_light: $alice_blue_light;
    lavender_blush_light: $lavender_blush_light;
    platinum_light: $platinum_light;
    bright_gray_light: $bright_gray_light;
    anti_flash_white_dark: $anti_flash_white_dark;
    outer_space: $outer_space;
    dark_jungle_green: $dark_jungle_green;
    peach_yellow: $peach_yellow;
    periwinkle: $periwinkle;
    black_olive: $black_olive;
    royal_blue: $royal_blue;
    lavender_blue: $lavender_blue;
    black_chocolate: $black_chocolate;
    linen: $linen;
    arsenic: $arsenic;
    gray_light: $gray_light;
    dark_black: $dark_black;
    platinum_dark: $platinum_dark;
}